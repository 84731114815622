import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import { Suspense } from 'react';
import Loader from './components/CommonComponents/Loader';

function App() {
  return (
   <>
      <Router >
          <Suspense fallback={<Loader/>}>
          <Routing />
          </Suspense>
      </Router>
   </>
  );
}

export default App;
